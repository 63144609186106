@import "../Variables";

footer {
    background-color: $blue-2-hex;
    color: $white;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    .footerColumns {
        flex: 100px 1 1;
        display: flex;
        padding: 10px 0;
        width: 100%;
        max-width: $laptop;
        margin: 0 auto;
        @include mobile {
            flex-wrap: wrap;
        }
        .footerLogo {
            max-width: 300px;
            img {
                max-width: 300px;
            }
            @include mobile {
                flex: 100% 0 0;
            }
        }
        div {
            flex: 25% 1 1;
            text-align: center;
            @include mobile {
                flex: 100% 0 0;
            }
        }
        ul {
            flex: 25% 1 1;
            text-align: left;
            list-style: none;
            li {
                .socialIcon {
                    margin-right: 5px;
                }
            }
            @include mobile {
                flex: 100% 0 0;
            }
        }
    }
    a {
        color: $white;
    }
    .baseline {
        flex: 22px 0 0;
        background-color: $blue-4-hex;
        text-align: center;
        padding: 15px 0;
        line-height: 22px;
        font-size: 16px;
    }
}