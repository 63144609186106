@import "../../Variables";
.loginPage {
    height:100%;
    display: flex;
    @include mobile {
        flex-wrap: wrap;
    }
    .backgroundBlob {
        position: fixed;
        z-index: -1;
        height: 200vh;
        width: 200vh;
        transform: rotate(-45deg);
        left: 70%;
        top: -40%;
        border-radius: 55px;
        transition: all 0.9s;
        background-color: $backgroundBlue;
        &.signingUp {
            left: -30%;
            top: -70%;
        }
    }
    .motivation {
        flex: 50% 1 1;
        p {
            font-size: 50px;
            padding: 20px 100px;
            font-weight: 700;
            max-width: 400px;
            animation: dropIn 0.7s ease-out forwards;
        }
    }
    .loginForm {
        flex: 50% 1 1;
        div {
            max-width: 350px;
            margin: 20px auto;
            top: 50%;
            transform: translateY(-50%);
            position: relative;
            text-align: left;
            input {
                display: block;
                appearance: none;
                border: none;
                font-size: 30px;
                font-family: 'Montserrat', sans-serif;
                padding: 15px 35px;
                border-radius: 15px;
                margin-bottom: 20px;
                width: 100%;
            }
            span.validation {
                color: red;
                display: block;
                margin-bottom: 15px;
            }
            button {
                display: block;
                appearance: none;
                border: none;
                background: transparent;
                margin: 20px auto;
                font-weight: 100;
                cursor: pointer;
                font-size: 14px;
                &.signupBtn,
                &.loginBtn {
                    font-size: 36px;
                }
            }
        }
    }
}