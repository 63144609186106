@import "../../Variables";

.settingsWindow {
    display: flex;
    flex-direction: row;
    .tabs {
        flex: 15% 1 1;
        min-width: 200px;
        .tabPanel {
            cursor: pointer;
            padding: 5px 10px;
            &.active {
                background-color: #{$charcoalLight + "22"}
            }
        }
    }
    .tabWindow {
        flex: 85% 1 1;
    }
}