@import "../Variables";

.fsLoader {
    position: fixed;
    height: 100vh;
    width: 100vw;
    left: 0;
    top: 0;
    .loadingBar {
        height: 5px;
        animation: loadingScreen 1s linear infinite forwards;
        background-color: $blue-1-hex;
        position: relative;
    }
    .loadingBlur {
        height: 100%;
        width: 100%;
        background-color: black;
        opacity: 0.2;
    }
}

.activeLoadingElement {
    position: fixed;
    width: 100vw;
    height: 5px;
    left: 0;
    top: 0;
    .loadingBar {
        height: 5px;
        animation: loadingScreen 1s linear infinite forwards;
        background-color: $blue-1-hex;
        position: relative;
    }
}

@keyframes loadingScreen {
    0% {
        left: 0%;
        width: 0%;
    }
    50% {
        left: 50%;
        width: 50%;
    }
    100% {
        left: 100%;
        width: 0%;
    }
}