main {
    min-height: calc(100vh - 82px - 200px);
}

.contentPage {
    max-width: 1140px;
    margin: 0 auto;
    h1 {
        text-align: center;
    }
}