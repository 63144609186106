@import "../../App.scss";

#createProjectPage {
    text-align: center;
    label {
        display: block;
    }
    input,
    select,
    button {
        display: block;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }
    input[type=checkbox] {
        display: inline;
        transform: none;
        left: auto;
    }
}