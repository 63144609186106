@import "../../App.scss";

#draggedArea {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    transition: all 0.2s;
    .dragBackground {
        height: 100%;
        width: 100%;
        background-color: $white;
        opacity: 0.3;
    }
    .dragBorder {
        position: absolute;
        top: 0; 
        left: 0;
        border: #c3c3c3 5px dashed;
        height: calc(100% - 10px - 60px);
        width: calc(100% - 10px - 60px);
        margin: 30px;
        border-radius: 30px;
        div {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            font-size: 40px;
        }
    }
}

.fileBrowser {
    margin: 0 auto;
    max-width: $laptop;
    min-height: 400px;
    width: 100%;
}

.projectUsageOuter {
    .totalUsage { 
        position: relative;
        width: 300px;
        height: 20px;
        background: $backgroundBlue;
        border-radius: 10px;
        border: 1px solid $charcoalLight;
        overflow: hidden;
        .currentUsage {
            height: 100%;
            position: absolute;
            background: linear-gradient($blue-4-hex, $blue-3-hex);
        }
        .usageText {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
}