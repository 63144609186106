@import "./Variables";

@font-face {
  font-family: 'Montserrat';
  src: url(./assets/Montserrat.ttf);
}
@font-face {
  font-family: 'Montserrat';
  src: url(./assets/Montserrat-Italic.ttf);
  font-style: italic;
}

html, body {
  font-family: 'Montserrat', sans-serif;
  overscroll-behavior-y: none;
  ::selection {
    background-color: $selectionBlue;
    color: $white;
  }
  input {
    &:focus {
      outline: none;
      box-shadow: 0px 0px 10px $selectionBlue;
    }
  }
  #root {
    overflow: hidden;
  }
}