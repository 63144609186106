/* Color Theme Swatches in Hex */
$blue-1-hex: #0540F2;
$blue-2-hex: #044BD9;
$blue-3-hex: #0583F2;
$blue-4-hex: #05AFF2;
$selectionBlue: #05C7F2;

$backgroundBlue: rgba(5, 173, 240, 0.1);
$white: rgb(255, 255, 255);

$calmRed: #EB3446;

$charcoal: #212121;
$charcoalLight: #434343;

$laptop: 1140px;
$mobile: 768px;

@keyframes dropIn {
    0% {
        opacity: 0;
        transform: translateY(-15%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@mixin mobile {
    @media screen and (max-width: $mobile) {
        @content;
    }
}