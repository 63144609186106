@import "../App.scss";

.toast {
    background: linear-gradient($charcoalLight, $charcoal);
    color: $white;
    position: fixed;
    right: 30px;
    bottom: 30px;
    font-size: 24px;
    font-weight: 600;
    padding: 20px;
    border-radius: 5px;
    transition: all 0.2s;
    box-shadow: 0px 0px 5px $charcoal;
}